@import '../variables.scss';

.overlay-transition {
  &-enter {
    transform: translateX(-100%);

    .overlay__close-button,
    .overlay__sidebar-content {
      opacity: 0;
    }

    .sidebar {
      left: -#{$sidebar-height};
    }

    .sidebar__body {
      opacity: 0;
    }
  }

  &-enter-active {
    transform: translateX(0);
    transition: 0.4s ease-in-out;

    .overlay__close-button {
      opacity: 1;

      transition: 0.4s 0.6s;
    }

    .sidebar {
      left: 0;
      transition: 0.4s 1.2s;
    }

    .sidebar__body {
      opacity: 1;
      transition: 0.5s 1.5s;
    }
  }

  &-exit {
    transform: translateX(0);

    .overlay__close-button {
      opacity: 1;
    }

    .sidebar {
      left: 0;
    }

    .sidebar__body {
      opacity: 1;
    }
  }

  &-exit-active {
    transform: translateX(-100%);
    transition: 0.4s 1s ease-in-out;

    .overlay__close-button {
      opacity: 0;
      transition: 0.4s 0.6s;
    }

    .sidebar {
      left: -#{$sidebar-height};
      transition: 0.2s 0.4s;
    }

    .sidebar__body {
      opacity: 0;
      transition: 0.2s;
    }
  }
}
