@import './variables.scss';

@font-face {
  font-family: 'pano_bold';
  src: url('./fonts/pano_bold.woff2') format('woff2'),
    url('./fonts/pano_bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

body {
  min-height: 500vh;
  // background-color: rgb(139, 201, 228);
  background-color: $darkBlue;
  background-color: #fff;
  // animation: body 1s linear;
  font-family: 'pano_bold', sans-serif;
  line-height: 1;
}

@keyframes body {
  to {
    background-color: rgb(19, 48, 97);
  }
}

* {
  outline: none;
}

.progress {
  animation: progress 1s linear;
  background-color: #ffffff;
  height: 3px;
  left: 0;
  position: fixed;
  top: 0;
  width: 0;
  /* Pause the animation */
  animation-play-state: paused;
  /* Bind the animation to scroll */
  animation-delay: calc(var(--scroll) * -1s);
  /* These last 2 properites clean up overshoot weirdness */
  animation-iteration-count: 1;
  animation-fill-mode: both;
}

@keyframes progress {
  to {
    background-color: red;
    width: 100%;
  }
}

.cube {
  --size: 50vmin;

  height: 0;
  left: 50%;
  perspective: 100vmin;
  position: fixed;
  top: 50%;
  width: 0;

  &__wrapper {
    animation: cube 1s linear;
    transform-style: preserve-3d;
    transform: rotateX(0);
    /* Pause the animation */
    animation-play-state: paused;
    /* Bind the animation to scroll */
    animation-delay: calc(var(--scroll) * -1s);
    /* These last 2 properites clean up overshoot weirdness */
    animation-iteration-count: 1;
    animation-fill-mode: both;
  }

  &__side {
    align-items: center;
    backface-visibility: visible;
    display: flex;
    height: var(--size);
    justify-content: center;
    left: calc(var(--size) * -1);
    position: absolute;
    top: calc(var(--size) * -0.5);
    width: calc(var(--size) * 2);

    // outline: 1px solid gold;

    $sides: (
      'front': 0,
      'bottom': -90,
      'back': 180,
      'top': 90,
    );

    @each $side, $value in $sides {
      &--#{$side} {
        animation: fade-#{$side} 1s calc(var(--scroll) * -1s );
        transform: rotateX(#{$value}deg) translateZ(calc(var(--size) / 2));

        /* Pause the animation */
  animation-play-state: paused;
  /* Bind the animation to scroll */
  animation-delay: calc(var(--scroll) * -1s);
  /* These last 2 properites clean up overshoot weirdness */
  animation-iteration-count: 1;
  animation-fill-mode: both;
      }
    }
  }

  &__button {
    -webkit-text-stroke: 1px black;
    color: $darkBlue;
    color: #fff;
    font-size: 4vw;
    text-transform: uppercase;
    transition: 0.5s ease;

    &:hover,
    &:active {
      color: #000;
    }

    &:focus {
      // outline: 0;
    }
  }
}


@keyframes cube {
  to {
    transform: rotateX(270deg);
  }
}

@keyframes fade-front {
  0% {
    opacity: 1;
  }

  33.333%,
  100% {
    opacity: 0;
  }
}

@keyframes fade-bottom {
  0%,
  66.666666%,
  100% {
    opacity: 0;
  }

  33.333333% {
    opacity: 1;
  }
}

@keyframes fade-back {
  0%,
  33.333333%,
  100% {
    opacity: 0;
  }

  66.666666% {
    opacity: 1;
  }
}

@keyframes fade-top {
  0%,
  75% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.moniker {
  color: #000;
  font-size: 4vw;
  text-transform: uppercase;
  margin: 0;
  -webkit-text-stroke: 1px black;

  span {
    -webkit-text-stroke: 1px black;
    color: $darkBlue;
    color: #fff;
  }

  &--sidebar {
    -webkit-text-stroke: 0.6px $light;
    font-size: 1.2em;

    span {
      -webkit-text-stroke: none;
    }
  }

  &--sidebar {

  }
}

.moniker-appear {
  opacity: 0;
  transform: scale(0.5);
  filter: blur(8px);
}
.moniker-appear-active {
  opacity: 1;
  transform: scale(1);
  transition: opacity 1s, transform 0.5s, filter 1.2s;
  transition-delay: 1s;
  filter: blur(0);
}
.moniker-appear-done {
  opacity: 1;
  transform: scale(1);
}


// :root * {
//   /* Pause the animation */
//   animation-play-state: paused;
//   /* Bind the animation to scroll */
//   animation-delay: calc(var(--scroll) * -1s);
//   /* These last 2 properites clean up overshoot weirdness */
//   animation-iteration-count: 1;
//   animation-fill-mode: both;
// }
