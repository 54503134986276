@import '../variables.scss';

.overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #000000;

  &__close-button {
    position: absolute;
    right: 2rem;
    top: 2rem;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover > svg circle {
      stroke-dasharray: 195, 195;
    }
  }

  &__close-button-svg {
    width: 64px;
    height: 64px;

    circle {
      fill: #000000;
      stroke-width: 2px;
      stroke: #ffffff;
      stroke-dasharray: 0, 195;
      transition:
        stroke-dasharray 0.3s ease-in-out,
        fill 0.6s;
    }
  }

  section {
    display: flex;
    justify-content: center;

    article {
      p {
        color: white;
        font-size: 21px;
        width: 40vw;
        line-height: 2.5;
        letter-spacing: 0.015em;
      }
    }
  }
}
