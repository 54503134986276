@import '../variables.scss';

.sidebar {
  align-items: center;
  border-top: 1px solid #cccccc;
  color: $light;
  display: flex;
  height: $sidebar-height;
  justify-content: space-around;
  position: relative;

  transform-origin: top left;
  transform: rotate(90deg) translateY(-#{$sidebar-height});
  width: 100vh;

  &__body {
    display: contents;
  }

  &__heading {
    font-size: 1.2em;
    margin: 0;
    text-transform: uppercase;
  }
}
