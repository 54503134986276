body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

button {
  background: none;
  border: 0;
  cursor: pointer;
  font-family: inherit;
}
