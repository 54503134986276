@import '../variables.scss';

.section {
  color: $light;
  padding: 0 25vw;
  position: relative;

  &__heading {
    font-size: 2em;
    line-height: 2;
    letter-spacing: 0.01em;
    z-index: 1;
    text-align: right;
  }

  &__image {
    width: 400px;
    height: 640px;
    background-color: #cccccc;
    position: absolute;
    top: 0vmin;
    right: 20vw;
    filter: saturate(0.1);
  }
}
